
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.logo {
  padding: 15px 0px;
  img {
    margin: 0px auto;
  }
}

.form {
  input[type=checkbox] {
    display: inline-block;
    width: auto;
    margin: 0px;
    height: 0px;
    margin-right: 10px;    
  }
}

.section-title {
  font-size: 18px;
  font-weight: bold;
}

.margin-top {
  margin-top: 30px;
}
